.Projects {
    margin: 100px 0 30px;
    .ProjectsHeader {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .Projects-title {
            cursor: default;
            text-align: center;
            position: relative;
            font-size: 3.5rem;

            &:after {
                position: absolute;
                content: '';
                bottom: -20px;
                left: 50%;
                height: 5px;
                width: 50px;
                transform: translate(-50%,0);
                background: #00ABFF;
                border-radius: 30px; } }

        .switch-btn-container {
            display: flex;
            justify-content: space-around;
            border: 4px solid #00ABFF;
            border-radius: 30px;
            width: 250px;
            background: grey;
            margin: 0;
            padding: 0; }

        .btn {
            padding: 10px 10px;
            border: 5px solid #00ABFF;
            border-radius: 50px; }

        .active {
            background: #00ABFF; } } }

.Project-box {
    border-radius: 10px;
    gap: 30px;
    margin: 50px 0;
    display: flex;

    justify-content: center;
    align-items: center;

    &:nth-child(odd) {
        flex-direction: row-reverse; } }
.left-side {
    width: 300px;
    min-width: 300px;
    img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 190px;
        margin: 20px 0;
        border-radius: 10px;
        box-shadow: 2px 2px 5px #0000002f;
        transition: transform .5s;
        cursor: pointer;

        &:hover {
            transform: scale(1.04); } } }

.btn-container {
    display: flex;
    justify-content: center;
    width: 100%;

    .code-btn, .live-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0px;
        border-radius: 10px;
        color: white;
        width: 100%;

        i {
            color: white;
            margin-right: 5px; } }


    .code-btn {
        margin-right: 15px;
        transition: background .5s; }
    .codeBtnEnabled {
        background: #454545;
        &:hover {
            background: darken(#454545, 10%); } }

    .codeBtnDisabled {
        cursor: default;
        background: #E0E0E0;
        color: #A7A7A7; }

    .live-btn {
        background: #00ABFF;
        transition: background .5s;
        &:hover {
            background: darken(#00ABFF, 10%); } } }

.project-name {
    margin: 0;
    font-size: 1.5rem;
    display: flex;

    .on-development {
        margin-left: 10px;
        color: #CA504D;
        background: #FEF1F2;
        font-size: .8rem;
        padding: 5px 10px;
        border-radius: 50px;
        border: 2px solid #FCDEDD; } }


.current-job {
    margin-left: 10px;
    color: #15803D;
    background: #F0FDF4;
    font-size: .8rem;
    padding: 5px 10px;
    border-radius: 50px;
    border: 2px solid #CFF0DB; }

.right-side {
    cursor: default;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 800px;

    .description {
        line-height: 1.8rem;
        font-family: 'Public Sans', sans-serif; }

    .tech-used {
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
        margin-bottom: 5px;

        p {
            margin: 0;
            padding: 7px 13px;
            color: #7f7f7f;
            background: #F9FAFB;
            font-size: .8rem;
            font-weight: 600;
            border-radius: 40px;
            border: 1.7px solid #EBEDEE; } }

    .flex-right {
        padding-left: 30px;
        justify-content: flex-end; }
    .flex-left {
        justify-content: flex-start; } }
@media (min-width:1000px) {
    .lg-hidden {
        display: none !important; } }
@media (max-width:1000px) {
    .btn-container {
        margin-top: 10px; }

    .sm-hidden {
        display: none !important; }
    .left-side {
        width: 100%;
        img {
            height: auto; } }
    .right-side .flex-right {
        padding-left: 0px;
        justify-content: flex-start; }



    .Project-box {
        gap: 0px;
        margin: 100px 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, 1fr);
        justify-items: center;
        align-items: center;

        &:nth-child(odd) {
            flex-direction: row; } }

    .right-side {
        padding: 0;

        .project-name {
            margin-top: 40px; } } }

@media (max-width: 700px ) {
    .Projects-title {
        font-size: 2.5rem !important;
        margin-bottom: -20px; } }
