@import './App';

.Showcase {
    position: relative;

    &-container {
        position: relative;
        -ms-scroll-limit-y-max: 100vw;
        max-width: 100vw;
        height: calc( 100vh - 200px );
        max-height: 1080px;
        display: flex;
        flex-direction: column;
        justify-content: center; }
    img {
        position: absolute;
        right: 0;
        top: 25%;
        opacity: .4;
        transition: none;
        height: 85%; }
    h3 {
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 1rem;
        margin-left: 5px;
        margin-bottom: -15px;
        text-transform: capitalize; }

    h1 {
        margin-left: -4px !important;
        padding-left: 0px !important;
        font-family: "Inter", sans-serif;
        line-height: 1rem;
        font-size: 5.5rem;
        color: $blue;
        margin-bottom: 60px; }

    p {
        margin-left: 5px;
        max-width: 600px;
        margin-bottom: 40px; } }

@media (min-width: 767px) {
    .Showcase img {
        display: block !important;
        right: 0px;
        top: 35%;
        opacity: 0.4;
        transition: none;
        height: 60%; } }

@media (max-width: 1100px) {
    .Showcase {
        &-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; }
        h3 {
            text-align: center;
            font-size: 1.2rem; }
        h1 {
            text-align: center;
            font-size: 2.4rem;
            line-height: 3rem;
            margin: 20px 10px -5px; }
        img {
            display: none; }
        p {
            font-size: 1rem;
            text-align: center;
            background: transparent; }

        .contact-btn {
            padding: 15px;
            font-size: 1rem; } } }

.resume-btn {
    cursor: pointer;
    border-radius: 10px;
    color: white !important;
    transition: background .5s, color .5s;
    padding: 15px 25px;
    font-weight: 700;
    position: relative;
    z-index: 10;
    background: $blue;

    &:hover {
        background: darken($blue, 10%) !important; } }

.black-bg {
    background: black; }

.white-bg {
    background: white; }
