@import './App';

.About {
    &-container {
        margin-bottom: 30px;
        padding: 400px 0;
        display: grid;
        grid-template-columns: 40% 60%;
        grid-gap: 20px;
        justify-content: center;
        align-items: center; }

    #myPicture {
        border-radius: 50%;
        height: 400px;
        border: 5px solid $blue;
        padding: 15px; }


    .info {
        padding: 50px;
        position: relative; }

    h1 {
        font-size: 3.5rem;
        position: relative;
        text-align: left;
        width: 180px;

        &::after {
            content: '';
            position: absolute;
            bottom: -10px;
            right: -40px;
            height: 5px;
            width: 80px;
            background: $blue;
            border-radius: 20px; } }


    p {
        font-size: 1.1rem;
        line-height: 2rem;
        letter-spacing: .5px; }

    .gray-dot-container {
        position: absolute;
        bottom: -40px;
        right: 0;

        img {
            height: 100px;
            width: 100px; } } }

@media screen and ( max-height: 550px ) {
    .About {
        &-container {
            padding: 100px 0; } } }

@media (max-width: 1300px) {
    .About {
        &-container {
            padding: 100px 0;
            justify-items: flex-end;
            align-items: center; }

        #myPicture {
            height: 200px; }

        h1 {
            font-size: 40px;
            text-align: center;
            width: 100%;
            &::after {
                right: 50%;
                width: 55px;
                transform: translate(50%, 0); } }
        p {
            font-size: 1rem; }
        .info {
            padding: 0px; } }

    .gray-dot-container {
        display: none; } }

@media ( max-width: 767px ) {
    .About {
        .info {
            margin-bottom: 80px; }
        &-container {
            height: auto;
            justify-items: center;
            grid-template-columns: 1fr; } } }
