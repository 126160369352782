@import './App';
.Nav {
    position: relative;
    max-width: 100%;

    &-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 150px; }

    .logo img {
        position: relative;
        height: 70px;
        z-index: 99999999; }

    .navbar {
        a {
            position: relative;
            cursor: pointer;
            font-size: 1rem;
            font-weight: 700;
            text-decoration: none;
            padding: 10px 20px;
            margin: 0 5px;
            border-radius: 10px;
            transition: background .3s;

            &:hover {
                background: #eeeeeeed; } } }




    .menu-icon {
        display: none;
        z-index: 999;
        font-size: 1rem;
        margin-right: 5px;
        padding: 20px; }

    nav.menu-nav {
        display: none;
        position: absolute;
        top: 100px;
        left: 0;
        margin: 0;
        width: 100vw;
        height: calc(100vh - 100px);
        z-index: 900;
        align-items: center;

        ul {
            padding-bottom: 200px;
            list-style: none;
            margin: 0;
            height: calc(100% - 100px);
            width: 80%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            a {
                font-size: 1.5rem;
                font-weight: 700;
                padding: 15px 60px;
                transition: background .3s;
                border-radius: 10px;
                cursor: pointer;

                &:hover {
                    background: #eeeeeeed; }
                &:first-child {
                    margin-top: 60px; } } } } }


@media (max-width: 700px) {
    .Nav {
        scroll-y: hidden;
        nav.menu-nav {
            display: flex; }
        &-container {
            height: 90px; }
        .logo img {
            height: 55px; }
        .navbar {
            display: none; }

        .menu-icon {
            display: block; } } }


.dark-mode-icon {
    font-size: 1.3rem;
    cursor: pointer;
    padding: 10px;

    &:hover {
        background: #eeeeeeed;
        border-radius: 50%; } }

.yellow-icon {
    color: yellow; }

.grey-icon {
    color: grey; }
