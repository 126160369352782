@import './App';
.Skills {
    position: relative;
    z-index: 1;
    padding: 100px 0 20px;
    &-container {
        background: #F1F1F1;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 50px; }
    h1 {
        font-size: 3.5rem;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: -15px;
            right: 50px;
            height: 5px;
            width: 65px;
            background: $blue;
            border-radius: 20px; } }

    .sub-title {
        max-width: 1000px;
        text-align: center;
        line-height: 2rem; }

    .grid-skills {
        width: 100%;
        padding: 20px 0 50px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(155px , 1fr));
        grid-gap: 15px;

        .skill-box {
            height: 50px;
            border-radius: 40px;
            cursor: default;

            i, p {
                color: black;
                font-weight: 700;
                color: #525252; }
            i {
                margin-right: 8px;
                width: 21px;
                font-size: 1.2rem; }

            .fa-code {
                font-size: 1rem !important; }

            p {
                margin: 0;
                padding: 10px;
                text-transform: capitalize;
                letter-spacing: .5px;
                list-style: circle; } }

        @media screen and ( max-width: 425px) {
            grid-template-columns: repeat(auto-fit, minmax(140px , 1fr));
            padding: 50px 0;
            grid-gap: 10px;

            .skill-box {
                &:hover {
                    transform: none; }
                p {
                    font-size: .85rem; } } } } }


@media (max-width: 700px ) {

    .Skills {
        background: #F1F1F1;
        &-container {
            padding: 0; }

        h1 {
            font-size: 2.5rem;

            &::after {
                transform: translate(-50%, 0);
                right: 0px;
                height: 5px;
                width: 55px;
                background: $blue;
                border-radius: 20px; } }
        p {
            font-size: 1rem;
            margin-bottom: 0px; } } }
