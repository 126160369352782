.grid-contact {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(auto-fit, minmax(450px,1fr)); }

.Contact {
    padding: 100px 0;
    background: #262626;

    &-left-side {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
            color: white;
            text-align: center;
            position: relative;
            margin-bottom: 10px;
            text-transform: capitalize;
            font-size: 3rem;

            // &:after
            //     content: ''
            //     position: absolute
            //     height: 5px
            //     width: 60px
            //     background: #00ABFF
            //     border-radius: 30px
            //     bottom: -25px
            //     left: 50%
 }            //     transform: translate(-50%,0)

        p {
            text-align: center;
            color: white;
            font-size: .95rem;
            line-height: 1.5rem; } }

    .contact-icons-container {
        margin-top: 10px;
        display: grid;
        max-width: 500px;
        margin: 0 auto;
        grid-template-columns: repeat(auto-fit, minmax(30px,1fr));
        justify-content: center;
        align-items: center;

        .icon-box {
            cursor: pointer;
            height: 70px;
            width: 70px;
            background: #333333;
            border-radius: 50%;
            margin: 5px 15px;

            display: flex;
            justify-content: center;
            align-items: center;
            transition: background .5s;
            box-shadow: 1px 1px 4px #00000080;
            &:hover {
                background: white; }
            i {
                color: #666666;
                font-size: 1.6rem; } } } }



@media (max-width: 700px) {
    h2 {
        font-size: 2rem !important; }

    .grid-contact {
        justify-content: space-around;
        grid-template-columns: repeat(auto-fit, minmax(300px,1fr)); }

    .Contact {
        &-right-side {
            display: grid;
            max-width: 300px;
            grid-template-columns: repeat(auto-fit, minmax(80px,1fr)); } } }
