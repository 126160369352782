.Footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;

    p {
        padding: 5px;
        text-align: center;
        letter-spacing: 1px;
        font-size: 0.8rem; } }

@media (max-width: 600px) {
    .Footer {
        p {
            font-size: .8rem; } } }
