@import url('https://fonts.googleapis.com/css?family=Public+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

* {
  font-family: "Inter", sans-serif;
  letter-spacing: .5px;
  font-size: 17px;


  a {
    text-decoration: none; }

  @media (max-width:1000px) {
    font-size: 16px; } }



$blue: #00ABFF;
@mixin container($max-width) {
  margin: 0 auto;
  width: 80%;
  max-width: $max-width;
  @media (max-width: 700px) {
    width: 90%; } }

.sm-container {
  @include container(1200px); }
.container {
  @include container(1300px); }

.lg-container {
  @include container(1600px); }
.App {
  position: relative;
  overflow-x: scroll;
  scroll-behavior: smooth; }

::-webkit-scrollbar {
  width: 10px;
  background: #c3c3c3aa; }

::-webkit-scrollbar:hover {
  padding: 5px; }

::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px; }

.no-scroll {
  overflow-y: hidden;
  overflow-x: hidden;
  overflow: hidden; }


.dark-mode {
  background: #121212;
  transition: background .5s;
  color: #f1f1f1; }


.clear-mode {
  background: white;
  transition: background .5s;
  color: #525252; }

.Contact-bar {
  z-index: 100;
  position: fixed;
  bottom: 0;
  left: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  i {
    outline: none;
    padding: 20px;
    color: #686D76;
    &:hover {
      color: darken(#686D76, 20%);
      transform: scale(1.04); }

    &:hover .contactName {
      visibility: visible; } }

  &-line {
    width: 2.5px;
    height: 200px;
    background: #686D76;
    border-radius: 10px; } }

.section-title {
  font-size: 3.5rem;
  position: relative;


  &::after {
      content: '';
      position: absolute;
      bottom: -15px;
      right: 50px;
      height: 5px;
      width: 65px;
      background: $blue;
      border-radius: 20px; } }

@media (max-width: 700px) {
  .section-title {
      font-size: 2.5rem !important; } }
.text-2 {
  font-size: 1.1rem;
  line-height: 2rem;
  letter-spacing: .5px; }

.contactName {
    position: absolute;
    margin-top: 15px;
    background: white;
    transform: translateX(15px);
    padding: 5px;
    box-shadow: 1px 1px 3px #00000060;
    border-radius: 3px;
    visibility: hidden;

    &:before {
        content: '';
        height: 150px;
        width: 150px;
        top: 50%;
        left: 50%;
        z-index: 1000; } }

@media screen and ( max-width: 1200px  ) {
  .Contact-bar {
    min-height: 56px;
    min-width: 56px;
    justify-content: center;
    flex-direction: row;
    bottom: 3vh;
    max-width: 250px;
    margin: 0 auto;
    left: 90%;
    transform: translateX(-50%);
    z-index: 90;
    i:hover {
      color: #00ABFF; } }
  .hide-icon {
    display: none; } }
